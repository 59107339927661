import React, { useCallback, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import { login } from "../../services/auth";

const TelegramLogin = () => {
  const isAuth = useSelector((state) => state?.auth?.isAuth);
  const status = useSelector((state) => state?.auth?.user?.data?.status);
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState(false);
  const [key, setKey] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      if (status) {
        return navigate("/account/profile");
      }
      else {
        return navigate("/account/settings");
      }
    }
  }, [isAuth]);
  const onSubmit = useCallback(async (key) => {
    try {
      if (key) {
        dispatch(login({ data: key }));
      }
      else {
        const text = await navigator.clipboard.readText();
        console.log(text)
        if (text) {
          dispatch(login({ data: text }));
        }
        setCaptcha(true)

      }
    } catch (error) {
      setCaptcha(true)
      console.error('Ошибка при чтении из буфера обмена: ', error);
    }
  }, [dispatch, setCaptcha]);


  return (
    <main>
      <Meta title="Авторизация через телеграм" />
      <Container>
        <section className="hv-100 sec-login d-flex flex-column align-items-center justify-content-center">
          <h1 className="h3 text-center">Скопируйте код, отправленный в телеграм и нажмите на кнопку</h1>
          <Row className='g-3 g-md-4 mb-5 mt-1 justify-content-center activate'>
            <input
              className={'code' + (!captcha ? " d-none" : "")}
              onChange={(e) => setKey(e.target.value)}
            />

            <button type='button' className='btn-1 h-100 w-100' onClick={() => onSubmit(key)}>Вставить и отправить код</button>
          </Row>
        </section>

      </Container>
    </main>
  );
};

export default TelegramLogin;
