import React, { useCallback, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Plaix from './svg/Plaix';

import useIsMobile from '../hooks/isMobile';


import { useForm, useWatch } from 'react-hook-form';
import { Md3P, MdHome, MdMenu, MdOutlineFactCheck, MdOutlineStarOutline } from "react-icons/md";
import { SlSocialFacebook, SlSocialInstagram, SlSocialVkontakte } from "react-icons/sl";
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import Logo from "../assets/imgs/logo-bml.svg";
import { createTaskAny } from '../services/task';
import { FaTelegram } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';


const Footer = ({ categories }) => {
  const isMobileLG = useIsMobile('991px');
  const userId = useSelector(state => state.auth?.user?.id);
  const dataStatus = useSelector((state) => state?.auth?.user?.data?.status);

  const [showAlert, setShowAlert] = useState(false);
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: userId,
  });
  const data = useWatch({ control });
  const onTask = useCallback(() => {
    createTaskAny({ type: "task", ...data, title: "Подписался на рассылку" })
      .then(() => {
        NotificationManager.success("Email отправлен");

      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при отправке"
        );
      });
  }, [data]);
  return (
    <>
      <footer>
        <Container className='h-100'>
          {
            (isMobileLG)
              ? <nav className='nav-mobile'>
                <ul>
                  <li>
                    <Link to='/' onClick={() => handleCloseAlert(false)}>
                      <MdHome />
                      <span>Главная</span>
                    </Link>
                  </li>
                  {!dataStatus ?
                    <>
                      <li>
                        <button onClick={setShowAlert}>
                          <MdOutlineStarOutline />
                          <span>Избранное</span>
                        </button>
                      </li>
                      <li>
                        <button onClick={setShowAlert}>
                          <MdOutlineFactCheck />
                          <span>Объявления</span>
                        </button>
                      </li>
                      <li>
                        <button onClick={setShowAlert}>
                          <Md3P />
                          <span>Сообщения</span>
                        </button>
                      </li>
                    </>
                    :
                    <>
                      <li>
                        <Link to='/account/favs'>
                          <MdOutlineStarOutline />
                          <span>Избранное</span>
                        </Link>
                      </li>
                      <li>
                        <Link to='/account/offers' end>
                          <MdOutlineFactCheck />
                          <span>Объявления</span>
                        </Link>
                      </li>
                      <li>
                        <Link to='/account/messages' end>
                          <Md3P />
                          <span>Сообщения</span>
                        </Link>
                      </li>
                    </>
                  }
                  <li>
                    <Link to="/account/" type="button" onClick={() => handleCloseAlert(false)}>
                      <MdMenu />
                      <span>Меню</span>
                    </Link>
                  </li>
                </ul>
              </nav>
              : <Row className='h-100 align-items-xl-center'>
                <Col xs={12} sm={6} md={4} lg={3} className="mb-4 mb-sm-0 ">
                  <Link to="/">
                    <img src={Logo} alt="БИЗНЕС MY LIFE" className="logo img-fluid" />
                  </Link>

                  <p className='mt-3 mb-2'>МЫ В СОЦИАЛЬНЫХ СЕТЯХ</p>
                  <ul className='list-unstyled footer-social'>

                    <li>
                      {/* <a href="https://vk.com/" target="_blank" rel="noreferrer"> */}
                      <SlSocialVkontakte className='fs-17' />
                      {/* </a> */}
                    </li>
                    <li>
                      <a href="https://t.me/bmlpro" target="_blank" rel="noreferrer">
                        <FaTelegram className='fs-17' />
                      </a>
                    </li>

                  </ul>
                </Col>

                <Col xs={12} sm={6} md={8} lg={9}>
                  <Row >
                    <Col xs={7} xl={6}>
                      <ul className="footer-menu">
                        {categories?.items?.length > 0 && categories?.items?.map(category =>
                          <li key={category.id}>
                            <Link to={'/catalog/' + category.uid}>{category.title.toUpperCase()}</Link>
                          </li>
                        )}
                        <li><Link to="/contacts">КОНТАКТЫ</Link></li>
                        <li><Link to="/blog">НОВОСТИ</Link></li>
                        <li><Link to="/info">ИНФОРМАЦИЯ ПО САЙТУ</Link></li>
                      </ul>
                    </Col>
                    <Col xs={5} xl={6}>
                      <p className='mb-3'>ПОДПИШИТЕСЬ НА НОВОСТИ: ВЫГОДНЫЕ БИЗНЕС ПРЕДЛОЖЕНИЯ, ЛУЧШИЕ ФРАНШИЗЫ И ПРОЕКТЫ</p>
                      <form action="" className='subscribe'>
                        <div className="d-flex">
                          <input type="email" placeholder='Введите e-mail' onChange={e => setValue("comment", e.target.value)} />
                          <button type='submit' className='btn-secondary' disabled={!isValid} onClick={handleSubmit(onTask)}>ок</button>
                        </div>
                        <label className="d-flex mt-3">
                          <input type="checkbox" defaultChecked={true}   {...register("accept")} />
                          <span className='flex-1 ms-2'>Согласен с правилами предоставления услуг</span>
                        </label>
                      </form>
                    </Col>

                  </Row>
                </Col>
                <Col md={12}>
                  <a href="https://plaix.ru/" target='_blank' className='dev-link ms-auto'>
                    <span>Создано в</span>
                    <Plaix />
                  </a>
                </Col>
              </Row>
          }
          <Modal show={showAlert} onHide={handleCloseAlert} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <h4 className="mb-4">Размещение объявлений и полный доступ к сайту будет доступен после заполнения информации профиля</h4>

              <div>
                <div className="d-flex justify-content-between">
                  <Link className="btn-1 mr-3" to="/account/settings" onClick={handleCloseAlert}>Заполнить профиль</Link>
                  <Button onClick={handleCloseAlert} className="mr-3">Закрыть</Button>
                </div>
              </div>

            </Modal.Body>
          </Modal>
        </Container>
      </footer>


    </>
  );
};

export default Footer;