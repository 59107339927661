import React, {
  useCallback
} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../components/Meta";
import City from "../../components/utils/City";
import Input from "../../components/utils/Input";
import ReturnTitle from "../../components/utils/ReturnTitle";
import { getImageURL } from "../../helpers/all";
import { editAccount, editAvatar } from "../../services/account";
import { authEditPassword } from "../../services/auth";
import { setUser } from "../../store/reducers/authSlice";

const Settings = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const {
    control,
    setValue,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      phone: user?.phone,
      firstName: user?.firstName,
      email: user?.email,
      data: {
        ...user.data,
        status: 1,
        type: user?.data?.type ?? "individual",
      }
    },
  });

  const data = useWatch({ control });

  const onEditAccount = useCallback((data) => {
    if (!user?.media) {
      return NotificationManager.error(
        "Загрузите фото"
      )
    }
    if (!data.data.city) {
      return NotificationManager.error(
        "Заполните город"
      )
    }
    if (!data.phone) {
      return NotificationManager.error(
        "Заполните номер телефона"
      )
    }

    if (data.data.type == "selfemployed") {
      if (!data.data.INN) {
        return NotificationManager.error(
          "Заполните ИНН"
        )
      }
    }
    if (data.data.type == "ip") {

      if (!data.data.OGRNIP) {
        return NotificationManager.error(
          "Заполните ОГРНИП"
        )
      }
      if (!data.data.INN) {
        return NotificationManager.error(
          "Заполните ИНН"
        )
      }
    }
    if (data.data.type == "llc") {
      if (!data.data.OGRN) {
        return NotificationManager.error(
          "Заполните ОГРН"
        )
      }
      if (!data.data.INN) {
        return NotificationManager.error(
          "Заполните ИНН"
        )
      }

    }
    editAccount(data)
      .then((res) => {
        res && dispatch(setUser(res));
        NotificationManager.success("Данные успешно обновлены");
      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, [user]);


  const {
    register: registerPassword,
    formState: { errors: errorsPassword, isValid: isValidPassword },
    handleSubmit: handleSubmitPassword,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const onSubmitPassword = useCallback((data) => {
    if (data.passwordNew != data.passwordConfirm) {
      return NotificationManager.error("Пароли не совпадают");
    }
    authEditPassword(data)
      .then(() => {
        NotificationManager.success("Пароль успешно обновлен");
      })
      .catch(
        (err) =>
          err &&
          NotificationManager.error(
            err?.response?.data?.error ?? "Ошибка при сохранении"
          )
      );
  }, []);

  const onUploadAvatar = useCallback(
    (e) => {
      e.preventDefault();
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        editAvatar(formData)
          .then((res) => {
            NotificationManager.success("Аватар успешно изменен");
            res && dispatch(setUser({ ...user, media: res.media }));
          })
          .catch(
            (err) =>
              err &&
              NotificationManager.error(
                err?.response?.data?.error ?? "Ошибка при сохранении"
              )
          );
      }
    },
    [user]
  );
  return (
    <section className="sec-settings">
      <Meta title="Настройки" />
      <ReturnTitle link="/account" title="Настройки" />

      {!user?.data?.status == 1 && <p className="text-center light-bg p-3 mb-4">Размещение объявлений и полный доступ будет предоставлен после заполнения всех обязательных полей</p>}

      <h6 className="mb-2 mb-md-3">Фото<span className="red">*</span>:</h6>

      <div className="box p-2 p-sm-3 mb-4">
        <Row className="align-items-center">
          <Col md={4}>
            <img src={getImageURL({
              path: user?.media,
              size: "mini",
              type: "user",
            })} alt="photo" className="img-fluid" />
          </Col>
          <Col md={8} className="files mt-4 mt-md-0">
            <label htmlFor="fileUpload" className="files-label btn-secondary">
              <span>Загрузить</span>
              <input
                type="file"
                id="fileUpload"
                className="hidden"
                onChange={(e) => onUploadAvatar(e)}
                accept=".png, .jpg, .jpeg"
              />
            </label>
            <p className="dark-gray fs-09 mt-3">
              (К загрузке разрешены файлы формата PNG, JPG, JPEG и весом не более 1 МБ. <br />Рекомендуем загружать фото не менее 300px в ширину и высоту.)
            </p>
          </Col>
        </Row>
      </div>

      <h6 className="mb-2 mb-md-3">Личные данные профиля</h6>
      <form className="box p-2 p-sm-3 p-md-4 mb-4">
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">Тип профиля</p>
          </Col>
          <Col md={8}>
            <select defaultValue={data.data?.type} onChange={(e) => setValue("data.type", e.target.value)} className="w-100">
              <option value="individual">Физическое лицо</option>
              <option value="selfemployed">Самозанятый</option>
              <option value="ip">ИП</option>
              <option value="llc">ООО</option>
            </select>
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">ФИО/Никнейм<span className="red">*</span></p>
          </Col>
          <Col md={8}>
            <input type="text" defaultValue={user.firstName} onChange={(e) => setValue("firstName", e.target.value)} />
          </Col>
        </Row>
        {(data?.data?.type == "ip" || data?.data?.type == "llc") && <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">Название компании</p>
          </Col>
          <Col md={8}>
            <input type="text" defaultValue={user.data?.companyName} onChange={(e) => setValue("data.companyName", e.target.value)} />
          </Col>
        </Row>
        }
        {(data?.data?.type == "individual" || data?.data?.type == "selfemployed") && <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">Дата рождения</p>
          </Col>
          <Col md={8}>
            <input type="date" defaultValue={user.birthday} onChange={(e) => setValue("birthday", e.target.value)} />
          </Col>
        </Row>
        }
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">Адрес электронной почты<span className="red">*</span></p>
            <p className="fs-09 color-1">Не показывается в профиле</p>
          </Col>
          <Col md={8}>
            {user.data.telegramChatId ?
              <Input type="login" defaultValue={user?.email} placeholder="E-mail" onChange={(e) => setValue("email", e)} /> :
              <span>{user.email}</span>}
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">Номер телефона<span className="red">*</span></p>
            <p className="fs-09 color-1">Не показывается в профиле</p>
          </Col>
          <Col md={8}>
            <Input type="tel" defaultValue={user?.phone} mask={"+ 7(999) 999-99-99"} onChange={(e) => setValue("phone", e)} />
          </Col>
        </Row>
        <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">Город<span className="red">*</span></p>
          </Col>
          <Col md={8}>
            <City defaultValue={user.data?.city} setCity={(e) => setValue("data.city", e)} city={data?.data?.city} />
          </Col>
        </Row>
        {data?.data?.type != "individual" && <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">ИНН<span className="red">*</span></p>
          </Col>
          <Col md={8}>
            <input type="text" defaultValue={user.data?.INN} onChange={(e) => setValue("data.INN", e.target.value)} />
          </Col>
        </Row>
        }
        {data?.data?.type == "ip" && <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">ОГРНИП<span className="red">*</span></p>
          </Col>
          <Col md={8}>
            <input type="text" defaultValue={user.data?.OGRNIP} onChange={(e) => setValue("data.OGRNIP", e.target.value)} />
          </Col>
        </Row>
        }
        {data?.data?.type == "llc" && <Row className="align-items-center mb-4">
          <Col md={4}>
            <p className="fw-medium">ОГРН<span className="red">*</span></p>
          </Col>
          <Col md={8}>
            <input type="text" defaultValue={user.data?.OGRN} onChange={(e) => setValue("data.OGRN", e.target.value)} />
          </Col>
        </Row>
        }
        {data?.data?.type == "llc" &&
          <Row className="align-items-center mb-4">
            <Col md={4}>
              <p className="fw-medium">Юридический адрес</p>
            </Col>
            <Col md={8}>
              <input type="text" defaultValue={user.data?.legalAddress} onChange={(e) => setValue("data.legalAddress", e.target.value)} />
            </Col>
          </Row>
        }
        <button type="submit" className="btn-primary mt-4" onClick={handleSubmit(onEditAccount)}>Cохранить</button>
      </form>

      <Row>
        <Col xs={12} sm={9} md={7}>
          <h6 className="mb-2 mb-md-3">Сменить пароль</h6>
          <form className="box p-2 p-sm-3 p-md-4">
            <Input
              autoComplete="new-password"
              className="mb-4"
              label="Старый пароль"
              type="password"
              name="passwordOld"
              errors={errorsPassword}
              placeholder="Введите старый пароль"
              register={registerPassword}
              validation={{
                required: "Введите пароль",
                minLength: {
                  value: 4,
                  message:
                    "Минимальный пароль должен состоять из 4-ех символов",
                },
              }}
            />
            <Input
              autoComplete="new-password"
              className="mb-4"
              label="Новый пароль"
              type="password"
              name="passwordNew"
              errors={errorsPassword}
              placeholder="Придумайте новый пароль"
              register={registerPassword}
              validation={{
                required: "Введите пароль",
                minLength: {
                  value: 4,
                  message:
                    "Минимальный пароль должен состоять из 4-ех символов",
                },
              }}
            />
            <Input
              className="mb-4"
              label="Повторите пароль"
              type="password"
              name="passwordConfirm"
              errors={errorsPassword}
              placeholder="Повторите пароль"
              register={registerPassword}
              validation={{
                required: "Введите пароль",
                minLength: {
                  value: 4,
                  message:
                    "Минимальный пароль должен состоять из 4-ех символов",
                },
              }}
            />
            <button
              type="submit"
              className="btn-primary"
              disabled={!isValidPassword}
              onClick={handleSubmitPassword(onSubmitPassword)}
            >Сохранить</button>
          </form>
        </Col>
      </Row>
    </section >
  );
};

export default Settings;
